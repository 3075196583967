<template>
  <div>
    <v-card-title>Please select a country</v-card-title>
    <v-card-text>
      <v-form ref="frmRegisterBank" lazy-validation class="form-inputs">
        <v-row>
          <v-col md="2" sm="3" cols="12">
            <v-radio-group :rules="[validations.required]" v-model="selectedCountry">
              <v-radio label="Australia" value="Australia"></v-radio>
              <v-radio label="Iran" value="Iran"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col md="10" sm="9" cols="12">
            <v-row v-if="selectedCountry === 'Australia'">
              <v-col md="4" sm="4" cols="12">
                <TextField type="number" dense :rules="[validations.required, validations.bsb]" showRequired v-model="bankModel.BSB" outlined label="BSB" />
              </v-col>
              <v-col md="4" sm="4" cols="12">
                <TextField type="number" dense :rules="[validations.required]" showRequired v-model="bankModel.AccountNumber" outlined label="Account Number" />
              </v-col>
              <v-col md="4" sm="4" cols="12">
                <TextField dense :rules="[validations.required]" showRequired v-model="bankModel.AccountName" outlined label="Account Name" />
              </v-col>
            </v-row>
            <v-row v-if="selectedCountry === 'Iran'">
              <v-col md="3" sm="3" cols="12">
                <TextField dense :rules="[validations.required]" showRequired v-model="bankModel.BankName" outlined label="Bank Name" />
              </v-col>
              <v-col md="3" sm="3" cols="12">
                <TextField
                  type="number"
                  placeholder="IR"
                  dense
                  :rules="[validations.required,validations.sheba]"
                  showRequired
                  v-model="bankModel.ShebaNumber"
                  outlined
                  label="Sheba Number"
                />
              </v-col>
              <v-col md="3" sm="3" cols="12">
                <TextField type="number" dense :rules="[validations.required]" showRequired v-model="bankModel.AccountNumber" outlined label="Account Number" />
              </v-col>
              <v-col md="3" sm="3" cols="12">
                <TextField dense :rules="[validations.required]" showRequired v-model="bankModel.AccountName" outlined label="Account Name" />
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="selectedCountry" md="12" sm="12" cols="12" class="text-right align-self-center pt-6">
            <v-btn color="primary_btn" :loading="loading" :disabled="loading || disableAdd" @click="save()"><v-icon>mdi-check</v-icon> add </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="selectedCountry === 'Australia'">
          <v-col md="12" cols="12"> <b>Payment History</b> </v-col>
          <v-col>
            <v-data-table :loading="loading" :hide-default-footer="true" class="pt-0" :items="australiaBankList" :headers="australiaHeaders">
              <template v-for="h in australiaHeaders" v-slot:[`header.${h.value}`]="{ header }">
                <v-tooltip top v-if="h.text === 'Default account'">
                  <template v-slot:activator="{ props }">
                    <span v-bind="props">{{h.text}}</span>
                  </template>
                  <span>You can only choose one account as your default account. Your payments will be deposited into your default account. </span>
                </v-tooltip>
                <span v-else>{{h.text}}</span>
              </template>
              <template v-slot:item.Country="{ item }">
                {{ item.Country }}
              </template>
              <template v-slot:item.BSB="{ item }">
                {{ item.BSB }}
              </template>
              <template v-slot:item.AccountName="{ item }">
                {{ item.AccountName }}
              </template>
              <template v-slot:item.AccountNumber="{ item }">
                {{ item.AccountNumber }}
              </template>
              <template v-slot:item.CreatedAt="{ item }">
                {{ formatDate(item.CreatedAt) }}
              </template>
              <template v-slot:item.IsDefault="{ item }">
                <v-col class="text-center">
                  <v-checkbox
                    class="d-inline-flex"
                    :disabled="item.IsDefault || loading  || hasActivePayrun"
                    v-model="item.IsDefault"
                    @change="changeItemStatus(item.Id)"
                  ></v-checkbox>
                </v-col>
              </template>
            </v-data-table>
            <v-card-subtitle><span class="warning-text"><strong>*</strong> You can only choose one account as your default account. Your payments will be deposited into your default account.</span></v-card-subtitle>
          </v-col>
        </v-row>
        <v-row v-if="selectedCountry === 'Iran'">
          <v-col md="12" cols="12"> <b>Payment History</b> </v-col>
          <v-col>
            <v-data-table :loading="loading" :hide-default-footer="true" class="pt-0" :items="iranBankList" :headers="iranHeaders">
              <template v-for="h in iranHeaders" v-slot:[`header.${h.value}`]="{ header }">
                <v-tooltip top v-if="h.text === 'Default account'">
                  <template v-slot:activator="{ props }">
                    <span v-bind="props">{{h.text}}</span>
                  </template>
                  <span>You can only choose one account as your default account. Your payments will be deposited into your default account. </span>
                </v-tooltip>
                <span v-else>{{h.text}}</span>
              </template>
              <template v-slot:item.Country="{ item }">
                {{ item.Country }}
              </template>
              <template v-slot:item.BankName="{ item }">
                {{ item.BankName }}
              </template>
              <template v-slot:item.ShebaNumber="{ item }">
                {{ item.ShebaNumber }}
              </template>
              <template v-slot:item.AccountName="{ item }">
                {{ item.AccountName }}
              </template>
              <template v-slot:item.AccountNumber="{ item }">
                {{ item.AccountNumber }}
              </template>
              <template v-slot:item.CreatedAt="{ item }">
                {{ formatDate(item.CreatedAt) }}
              </template>
              <template v-slot:item.IsDefault="{ item }">
                <v-col class="text-center">
                  <v-checkbox
                    class="d-inline-flex"
                    :disabled="item.IsDefault || loading  || hasActivePayrun"
                    v-model="item.IsDefault"
                    @change="changeItemStatus(item.Id)"
                  ></v-checkbox>
                </v-col>
              </template>
            </v-data-table>
            <v-card-subtitle><span class="warning-text"><strong>*</strong> You can only choose one account as your default account. Your payments will be deposited into your default account.</span></v-card-subtitle>

          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-dialog persistent v-if="showConfirm" v-model="showConfirm" @keydown.esc="cancel" max-width="500px">
      <confirm-dialog :loading="loading" @confirm="confirmSave" @cancel="cancel" :message="confirmModel"> </confirm-dialog>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Teammember } from "shared-components/src/models/Teammember";
import validations from "shared-components/src/utils/validations";
import Utils from "shared-components/src/utils/Utils";
import { mapMutations } from "vuex";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import { BankAccount } from "shared-components/src/models/BankAccount";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import store from "@/store";

export default defineComponent({
  props: ["bankAccountModel", "submitting", "bankAccounts", "paymentMethodSaveMessage", "hasActivePayrun"],
  data() {
    return {
      validations,
      bankModel: {} as BankAccount,
      bankList: [] as BankAccount[],
      iranBankList: [] as BankAccount[],
      australiaBankList: [] as BankAccount[],
      loading: false,
      confirmModel: {
        title: "",
        text: "",
      },
      showConfirm: false,
      selectedCountry: "",
      selectedId: "",
      paymentMethodMessage: "",
      walletAddress: "",
      inline: 0,
      teamMemberInfo: store.state.teamMemberInfo as Teammember,
      australiaHeaders: [
        { title: "Country", align: "center", value: "Country" },
        { title: "BSB", align: "center", value: "BSB" },
        { title: "Account name", align: "center", value: "AccountName" },
        { title: "Account number", align: "center", value: "AccountNumber" },
        { title: "Request date", align: "center", value: "CreatedAt" },
        { title: "Default account", align: "center", value: "IsDefault" },
      ],
      iranHeaders: [
        { title: "Country", align: "center", value: "Country" },
        { title: "Bank name", align: "center", value: "BankName" },
        { title: "Sheba number", align: "center", value: "ShebaNumber" },
        { title: "Account name", align: "center", value: "AccountName" },
        { title: "Account number", align: "center", value: "AccountNumber" },
        { title: "Request date", align: "center", value: "CreatedAt" },
        { title: "Default account", align: "center", value: "IsDefault" },
      ],
    };
  },
  components: {
    ConfirmDialog,
  },
  computed:{
    disableAdd(){
      return  this.hasActivePayrun && this.bankAccounts.length == 0;
    }
  },
  watch: {
    paymentMethodSaveMessage(newVal){
      this.paymentMethodMessage = newVal;
    },
    selectedCountry(newVal) {
      this.bankModel = {} as BankAccount;
      this.bankModel.Country = newVal;
    },
    bankAccounts(newVal) {
      this.loadDefaultSelectedCountry(newVal);
    },
    submitting(newVal) {
      this.loading = newVal;
    },
  },
  mounted() {
    this.loadDefaultSelectedCountry(this.bankAccounts);
  },
  methods: {
    loadDefaultSelectedCountry(accounts: any) {
      this.iranBankList = accounts.filter((c: any) => c.Country === "Iran");
      this.australiaBankList = accounts.filter((c: any) => c.Country === "Australia");
      this.bankList = accounts;
    },
    changeItemStatus(id: any) {
      this.selectedId = id;
      this.confirmModel = {
        title: ProjectMessages.defaultPeymentMethod,
        text: ProjectMessages.bankChangeDefaultMessage,
      };
      this.showConfirm = true;
    },
    cancel() {
      this.showConfirm = false;
      if (this.selectedId) {
        var findItem = this.bankList.find((c) => c.Id === this.selectedId);
        if (findItem) {
          findItem.IsDefault = false;
        }
      }
      this.selectedId = "";
    },
    confirmSave() {
      if (this.selectedId) {
        var findItem = this.bankList.find((c) => c.Id === this.selectedId);
        if (findItem) {
          this.$emit("save", findItem);
        }
      } else {
        this.bankModel.Country;
        if(this.bankList.length === 0){
          this.bankModel.IsDefault = true;
        }
        this.$emit("save", this.bankModel);
      }
      this.showConfirm = false;
      this.selectedId = "";
      this.bankModel = {} as BankAccount;
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    createHtml() {
      var htmlText = "";
      htmlText += `<p class="mt-4 mb-0"><strong>Country: ${this.bankModel.Country}</strong></p>`;
      htmlText += `<p class="mb-0"><strong>Account Name: ${this.bankModel.AccountName}</strong></p>`;
      htmlText += `<p class="mb-0"><strong>Account Number: ${this.bankModel.AccountNumber}</strong></p>`;
      htmlText += this.bankModel.BSB ? `<p><strong>BSB: ${this.bankModel.BSB}</strong></p>` : "";
      htmlText += this.bankModel.ShebaNumber ? `<p><strong>Sheba Number: ${this.bankModel.ShebaNumber}</strong></p>` : "";
      return htmlText;
    },
    async save() {
      const isValid = await (this.$refs.frmRegisterBank as any).validate();
      if (isValid.valid) {
        var htmlText = this.createHtml();
        this.confirmModel = {
          title: ProjectMessages.addPeymentMethod,
          text: `${this.paymentMethodMessage}${ProjectMessages.bankAddMessage}${htmlText}`,
        };
        this.showConfirm = true;
      }
    },
  },
});
</script>
