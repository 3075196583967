<template>
  <div class="assessment-list">
    <v-overlay v-model="overlay" scroll-strategy="block" location-strategy="connected" opacity="0.8" persistent @click="clickOnOverlay"> </v-overlay>
    <AssessmentFilter
      @showOverlay="filterToggle"
      @time-chip-closed="handelTimeChipClosed"
      @doSearch="handleSearchFilter"
      :showAssessmentBtn="assessmentBtnVisiblity"
      :top="topValue"
      :height="heightValue"
      :padding="paddingValue"
      :overlay="overlay"
      :showFilter="showFilter"
      :skills="skills"
    ></AssessmentFilter>

    <div class="card-list">
      <div class="loading-container" v-if="isLoading">
        <v-progress-circular color="primary" indeterminate :size="80"> Loading</v-progress-circular>
      </div>
      <v-row>
        <v-col xs="12" sm="4" md="3" v-for="assessment in assessments" :key="assessment.Id" v-if="!isLoading">
          <AssessmentItems :assessment="assessment"></AssessmentItems>
        </v-col>
        <v-col cols="12" v-if="showEmptyAssessmentCard && !isLoading">
          <empty-course-card></empty-course-card>
        </v-col>
      </v-row>
    </div>

    <v-alert v-if="showErrorAlert" type="error" dismissible @input="showErrorAlert = false"> Error fetching data. Please try again. </v-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AssessmentItems from "./AssessmentItems.vue";
import AssessmentFilter from "./AssessmentFilter.vue";
import CoreSkill from "../../../../../ProjectPortal-SharedComponents/src/models/CoreSkill";
import AssessmentModel from "../../../../../ProjectPortal-SharedComponents/src/models/AssessmentModel";
import CoreSkillService from "../../../services/CoreSkillService";
import AssessmentService from "../../../services/AssessmentService";
import FindAssessmentsRequest from "shared-components/src/models/requests/FindAssessmentsRequest";

export default defineComponent({
  components: {
    AssessmentItems,
    AssessmentFilter,
  },
  props: {
    showAsPage: {
      type: Boolean,
      default: false,
    },
    topValue: {
      type: String,
      default: "1px",
    },
    heightValue: {
      type: String,
      default: "610px",
    },
    paddingValue: {
      type: String,
      default: "40px",
    },
    assessmentBtnVisiblity: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchQuery: {} as FindAssessmentsRequest,
      showFilteredTime: false,
      overlay: false,
      showFilter: false,
      isLoading: false,
      showErrorAlert: false,
      showEmptyAssessmentCard: false,
      showAssessmentFilter: false,
      selectedTime: null as any,
      skills: [] as CoreSkill[],
      assessments: [] as AssessmentModel[],
    };
  },
  computed: {
    assessmentData(): AssessmentModel[] {
      return this.assessmentData || [];
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      await this.loadSkillsList();
      this.assessments = await AssessmentService.GetList();
    } catch (error) {
      console.error("Error fetching data:", error);
      this.showErrorAlert = true;
    } finally {
      this.isLoading = false;
    }
  },
  // async created() {
  //   try {
  //     this.isLoading = true;
  //     await this.loadSkillsList();
  //     this.assessments = await AssessmentService.GetList();
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     this.showErrorAlert = true;
  //   } finally {
  //     this.isLoading = false;
  //   }
  // },
  methods: {
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    handleSearchFilter(filterValues: { duration: any; level: any; skill: any; text: any }) {
      this.showFilteredTime = filterValues.duration !== null;
      this.searchQuery.Duration = filterValues.duration;
      this.searchQuery.Level = filterValues.level;
      this.searchQuery.Technology = filterValues.skill;
      this.searchQuery.Text = filterValues.text;
      this.searchQuery = { ...this.searchQuery };
    },
    handelTimeChipClosed() {
      this.selectedTime = null;
    },
    goToAddAssessment() {
      this.$router.push({ name: "add-assessment" });
    },
    handleNewAssessment(newAssessment: any) {
      if (newAssessment) {
        this.assessmentData.unshift(newAssessment);
      }
    },
    clickOnOverlay() {
      this.showFilter = false;
      this.overlay = !this.overlay;
    },
    addToRoadMap(assessmentDetails: any) {
      this.$emit("AddAssessmentToRoadMap", assessmentDetails);
      this.handelBackToRoadMap();
    },
    handelBackToRoadMap() {
      this.$emit("CloseAssessmentsList");
    },
    filterToggle(filterVisible: any) {
      this.overlay = !this.overlay;
      this.showFilter = filterVisible;
      this.$emit("showOverlay", filterVisible);
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.isLoading = true;
        this.showEmptyAssessmentCard = false;
        this.assessments = [];
        AssessmentService.GetList(this.searchQuery).then((res: any) => {
          if (res.length <= 0) {
            this.showEmptyAssessmentCard = true;
          }
          this.assessments = res;
          this.isLoading = false;
        });
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.assessment-list {
  position: relative;

  .card-list {
    padding-top: 100px;
    display: flex;
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
    }
  }
}
</style>
