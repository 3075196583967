import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-text"
}
const _hoisted_2 = { class: "warning-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CryptoCurrencyComponent = _resolveComponent("CryptoCurrencyComponent")!
  const _component_BankAccountComponent = _resolveComponent("BankAccountComponent")!
  const _component_WiseAccountComponent = _resolveComponent("WiseAccountComponent")!
  const _component_PaypalComponent = _resolveComponent("PaypalComponent")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, { loading: _ctx.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Choose a payment method ")
          ])),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_subtitle, { class: "success-border success-text text-h6 pa-4" }, {
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createTextVNode("Please note that preferred payment method can only be changed once every 6 months. You may change or add new accounts to your preferred payment methods at any time, provided that there is not outstanding/unpaid Payrun.")),
                (_ctx.endDate)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                      _cache[2] || (_cache[2] = _createTextVNode("You can not change your payment method until ")),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.formatDate(_ctx.endDate)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, {
              justify: "center",
              class: "mt-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_radio_group, {
                  modelValue: _ctx.paymentType,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paymentType) = $event)),
                  inline: "",
                  disabled: _ctx.teamMemberInfo.PaymentMethodType != 0 && _ctx.isReadonly,
                  class: "justify-item-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_radio, {
                      label: "Cryptocurrency",
                      disabled: _ctx.teamMemberInfo.PaymentMethodType != 1 && _ctx.teamMemberInfo.PaymentMethodType != 0 && _ctx.isReadonly,
                      value: 1
                    }, {
                      label: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createElementVNode("div", null, [
                          _createTextVNode("Cryptocurrency"),
                          _createElementVNode("span", { class: "text-overline" }, " USDT-BEP20")
                        ], -1)
                      ])),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_v_radio, {
                      label: "Bank Account",
                      disabled: _ctx.teamMemberInfo.PaymentMethodType != 2 && _ctx.teamMemberInfo.PaymentMethodType != 0 && _ctx.isReadonly,
                      value: 2
                    }, null, 8, ["disabled"]),
                    _createVNode(_component_v_radio, {
                      label: "Wise Account",
                      value: 3,
                      disabled: _ctx.teamMemberInfo.PaymentMethodType != 3 && _ctx.teamMemberInfo.PaymentMethodType != 0 && _ctx.isReadonly
                    }, null, 8, ["disabled"]),
                    _createVNode(_component_v_radio, {
                      label: "Paypal",
                      value: 4,
                      disabled: _ctx.teamMemberInfo.PaymentMethodType != 4 && _ctx.teamMemberInfo.PaymentMethodType != 0 && _ctx.isReadonly
                    }, null, 8, ["disabled"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }),
            (_ctx.hasActivePayrun)
              ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                  key: 0,
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_2, [
                      _cache[5] || (_cache[5] = _createElementVNode("strong", null, "*", -1)),
                      _createTextVNode(" You can not change default payment method when you have a active payrun. (" + _toDisplayString(_ctx.activePayrunDates) + ")", 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.paymentType === 1)
              ? (_openBlock(), _createBlock(_component_CryptoCurrencyComponent, {
                  key: 1,
                  paymentMethodSaveMessage: _ctx.paymentMethodSaveMessage,
                  onSave: _ctx.save,
                  cryptos: _ctx.cryptoList,
                  submitting: _ctx.loading,
                  hasActivePayrun: _ctx.hasActivePayrun
                }, null, 8, ["paymentMethodSaveMessage", "onSave", "cryptos", "submitting", "hasActivePayrun"]))
              : _createCommentVNode("", true),
            (_ctx.paymentType === 2)
              ? (_openBlock(), _createBlock(_component_BankAccountComponent, {
                  key: 2,
                  paymentMethodSaveMessage: _ctx.paymentMethodSaveMessage,
                  onSave: _ctx.save,
                  bankAccounts: _ctx.bankList,
                  submitting: _ctx.loading,
                  hasActivePayrun: _ctx.hasActivePayrun
                }, null, 8, ["paymentMethodSaveMessage", "onSave", "bankAccounts", "submitting", "hasActivePayrun"]))
              : _createCommentVNode("", true),
            (_ctx.paymentType === 3)
              ? (_openBlock(), _createBlock(_component_WiseAccountComponent, {
                  key: 3,
                  paymentMethodSaveMessage: _ctx.paymentMethodSaveMessage,
                  onSave: _ctx.save,
                  wiseAccounts: _ctx.wiseList,
                  submitting: _ctx.loading,
                  hasActivePayrun: _ctx.hasActivePayrun
                }, null, 8, ["paymentMethodSaveMessage", "onSave", "wiseAccounts", "submitting", "hasActivePayrun"]))
              : _createCommentVNode("", true),
            (_ctx.paymentType === 4)
              ? (_openBlock(), _createBlock(_component_PaypalComponent, {
                  key: 4,
                  paymentMethodSaveMessage: _ctx.paymentMethodSaveMessage,
                  onSave: _ctx.save,
                  paypals: _ctx.paypalList,
                  submitting: _ctx.loading,
                  hasActivePayrun: _ctx.hasActivePayrun
                }, null, 8, ["paymentMethodSaveMessage", "onSave", "paypals", "submitting", "hasActivePayrun"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}