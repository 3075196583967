<template>
  <v-card :loading="pageLoading" :disable="pageLoading">
    <v-card-title>
      <v-row no-gutters class="align-center">
        Commitment List
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-switch dense label="Active" v-model="isActive" color="success" hide-details></v-switch>
        <v-spacer></v-spacer>
        <v-switch dense label="Card View" v-model="isCardView" color="success" hide-details></v-switch>
      </v-row>
    </v-card-title>
    <v-card-text class="commitment-body">
      <v-data-table :items="tempDesserts" :headers="headers" :loading="loading" :custom-sort="customSort" v-if="!isCardView">
        <template v-slot:item.Name="{ item }">
          <strong>{{ item.Name }}</strong>
        </template>
        <template v-slot:item.SeniorityLevel="{ item }">
          {{ item.SeniorityLevel }}
        </template>
        <template v-slot:item.LineManagerId="{ item }">
          {{ item.LineManagerName }}
        </template>
        <template v-slot:item.Status="{ item }">
          <CommitmentsChips :status="checkCommitmentStatus(item.StatusV2)"/>
        </template>

        <template v-slot:item.StartDate="{ item }">
          {{ formatDate(item.StartDate) }}
        </template>
        <template v-slot:item.EndDate="{ item }">
          {{ formatDate(item.EndDate) }}
        </template>
        <template v-slot:item.ProjectNames="{ item }">
          {{ item.ProjectNames ? formatProjects(item.ProjectNames) : "" }}
        </template>
        <template v-slot:item.Type="{ item }">
          {{ item.HoursPerWeekRadio ? "Hours Per week" : "Casual" }}
          <div v-if="item.HoursPerWeekRadio"><strong>Min: </strong>{{ item.HoursPerWeekMin }}</div>
          <div v-if="item.HoursPerWeekRadio"><strong>Max: </strong>{{ item.HoursPerWeekMax }}</div>
        </template>
        <template v-slot:item.Action="{ item }">
          <v-menu
            offset-y
            close-on-click
          >
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item link @click="showUpdateStatus(false, item.id)" title="Approve"/>
              <v-list-item link @click="showUpdateStatus(true, item.id)" title="Reject"/>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <CommitmentCardView v-else :items="tempDesserts" />
    </v-card-text>
  </v-card>
  <v-dialog v-model="showUpdateStatusPopup" persistent max-width="400px">
    <v-card>
      <v-card-title>
        {{ !rejectMode ? "Approve Commitment" : "Reject Commitment" }}
      </v-card-title>
      <v-card-text>
        <span>Are you sure about this action ?</span>
        <TextAreaField v-if="rejectMode" v-model="rejectMessage" label="Reason of Rejecting" :rules="[validations.required]"/>
      </v-card-text>
      <v-card-actions>
        <v-col md="12">
          <v-btn :loading="loading" class="primary_btn mr-5" dark @click="updateCommitmentStatus">{{!rejectMode ? "Yes! Approve it" : 'Yes! Reject it'}}</v-btn>
          <v-btn :loading="loading" class="secondary_btn" dark @click="showUpdateStatusPopup = false">Cancel</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">

import LoadingComponent from "shared-components/src/components/Loading.vue";
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import Infrastructure from "shared-components/src/utils/Infrastructure";
import CommitmentsChips from "shared-components/src/components/Commitment/CommitmentsChips.vue";
import CommitmentCardView from "../../components/commitment/CommitmentCardView.vue";
import validations from "shared-components/src/utils/validations";
import { CommitmentModel, TeammemberApi, UpdateCommitmentStatusRequestModel } from "shared-components/src/services/openApi/api";
import CommitmentService from "shared-components/src/services/CommitmentService";
import store from "@/store";
import { CommitmentModelStatusV2Enum } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    LoadingComponent,
    CommitmentCardView,
    CommitmentsChips
  },
  computed: {
    teamMemberInfo() {
      return store.state.teamMemberInfo;
    },
  },
  data() {
    return {
      validations,
      showUpdateStatusPopup: false,
      rejectMode: false,
      rejectMessage: "",
      selectedCommitmentId: "",
      pageLoading: true,
      totalDesserts: 0,
      desserts: [] as CommitmentModel[],
      tempDesserts: [] as CommitmentModel[],
      isActive: false,
      isCardView: window.innerWidth < 1024 ? true : false,
      confirmModel: {
        title: "",
        text: "",
      },
      loading: true,
      headers: [
        { width: "20%", title: "Name", align: "start", value: "Name" },
        { width: "12%", title: "Seniority level", value: "SeniorityLevel" },
        { width: "10%", title: "Line Manager", value: "LineManagerId" },
        { width: "10%", title: "Start date", value: "StartDate" },
        { width: "10%", title: "End date", value: "EndDate" },
        { width: "10%", title: "Projects", value: "ProjectNames", sortable: false },
        { width: "5%", title: "Status", value: "Status" },
        { width: "10%", title: "Type", value: "Type", sortable: false },
        { width: "10%", title: "Action", value: "Action", sortable: false },
      ],
    };
  },
  async mounted() {
    await this.fetchCommitments();
  },
  methods: {
    showUpdateStatus(rejectMode: boolean, commitmentId: string) {
      this.rejectMode = rejectMode;
      this.showUpdateStatusPopup = true;
      this.selectedCommitmentId = commitmentId;
    },
    async updateCommitmentStatus(commitmentId: any) {
      if (this.selectedCommitmentId != "") {
        this.loading = true;
        let request;
        if (this.rejectMode) {
          request = { message: this.rejectMessage, status: "Rejected" } as UpdateCommitmentStatusRequestModel
        } else {
          request = { status: "Approved" } as UpdateCommitmentStatusRequestModel
        }
        await CommitmentService.teammemberUpdateStatus(this.selectedCommitmentId, request);
        this.showUpdateStatusPopup = false
        this.rejectMessage = "";
        this.selectedCommitmentId = "";
        this.loading = false;
      }
    },
    async fetchCommitments(): Promise<void> {
      this.loading = true;
      const updatedOptions = { params: { addExtraDetail: this.isCardView, }, };
      try {
        var response = (await new TeammemberApi().getCommitmentByTeammemberId(updatedOptions)).data as any;
        this.desserts = response.value;
      }
      finally {
        this.tempDesserts = this.desserts;
        this.loading = false;
        this.pageLoading = this.loading;
      }
    },

    formatTeammembers($items: Array<{ id: string; TmFullName: string }>): string {
      return $items.map((item) => item.TmFullName).join(", ");
    },
    formatProjects($items: string[]): string {
      return $items.join(", ");
    },
    isActiveTeammember(teammember: any, activeCommitments: any) {
      return activeCommitments.some((c: any) => c.TeamMemberIds && c.TeamMemberIds.includes(teammember.Id));
    },
    checkCommitmentStatus(item: CommitmentModelStatusV2Enum | undefined) {
      return Infrastructure.checkCommitmentStatus(item);
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    clearFilters() {
      this.isActive = false;
    },
    filterDesserts() {
      return this.desserts.filter((item: CommitmentModel) => {
        let exist = true;
        if (this.isActive) {
          const theDate = new Date();
          exist = item.StatusV2 == CommitmentModelStatusV2Enum.Active ? true : false;
          if (!exist) {
            return;
          }
        }
        if (exist) {
          return item;
        }
      });
    },
    customSort(items: any, index: any, isDesc: any) {
      items.sort((a: any, b: any) => {
        if (index[0] === "StartDate" || index[0] === "EndDate") {
          if (!isDesc[0]) {
            return new Date(a[index]).getTime() - new Date(b[index]).getTime();
          } else {
            return new Date(b[index]).getTime() - new Date(a[index]).getTime();
          }
        }
        if (index[0] === "Status") {
          if (!isDesc[0]) {
            return this.checkCommitmentStatus(a) < this.checkCommitmentStatus(b) ? -1 : 1;
          } else {
            return this.checkCommitmentStatus(b) < this.checkCommitmentStatus(a) ? -1 : 1;
          }
        }
      });
      return items;
    },
  },
  watch: {
    isActive() {
      this.tempDesserts = this.filterDesserts();
    },
    isCardView() {
      this.pageLoading = true;
      this.fetchCommitments();
    },
  },
});
</script>
<style scoped>
@media (max-width: 383px) {
  .commitment-body {
    padding-top: 50px;
  }
}
</style>
