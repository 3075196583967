<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <h3>Your email address hasn't been verified, please click on the verification link you have received in your email</h3>
      </v-col>
      <v-col>
        <v-btn :loading="isLoading" color="secondary" @click="resend"> <v-icon>mdi mdi-refresh</v-icon>Resend </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import UserService from "@/services/UserService";
import { Profile } from "shared-components/src/definitions/config";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      pageLoading: false,
      isLoading: false,
      interval: 0,
      callbackUrl: `${window.location.origin}/register/verify-email`,
    };
  },
  async mounted() {
    await UserService.sendEmailConfirmation(this.callbackUrl);
  },
  methods: {
    resend() {
      this.isLoading = true;
      try {
        UserService.sendEmailConfirmation(this.callbackUrl).then((res) => {
          store.dispatch("showSuccessMessage", "Verfification email has been sent, Please check your email!")
        });
      } finally {
        this.isLoading = false;
      }
    },
    async reload() {
      try {
        const userInfo = await UserService.reload();
        if (userInfo.emailVerified) {
          store.dispatch("setUserInfo", { userInfo, vm: this });
          this.$router.push("/team-member/dashboard");
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
});
</script>
