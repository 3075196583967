<template>
  <div class="filter-container">
    <div class="sticky-navbar" :style="{ top: top }" @click.stop>
      <v-row class="default-section">
        <v-btn @click="toggleFilter" class="filter-button"> Filter </v-btn>
        <v-row>
          <v-col>
            <div class="part">
              <v-text-field
                placeholder="Category"
                solo
                hide-details
                @click:append-inner="searchHandler"
                @keyup.enter="searchHandler"
                v-model="searchCategory"
              >
                <v-icon color="black" @click="searchHandler" class="clickable-icon"> mdi-magnify </v-icon>
              </v-text-field>
            </div>
            <v-row class="mt-1" v-if="criteria.selectedSkill.length > 0 || criteria.selectedTime || criteria.selectedLevel">
              <v-col cols="12">
                <v-chip
                  @click:close="closeSkillChip(index)"
                  close
                  v-for="(skill, index) in criteria.selectedSkill"
                  :key="index"
                  class="mr-2"
                  :color="backgrounds.skill"
                >
                  Skill: {{ getSkillName(skill) }}
                </v-chip>
                <v-chip @click:close="closeTimeChip" close v-if="criteria.selectedTime" class="mr-2" :color="backgrounds.duration">
                  Duration: Between
                  {{ (criteria.selectedTime as any).split(",")[0] }} - {{ (criteria.selectedTime as any).split(",")[1] }}h
                </v-chip>
                <v-chip
                  @click:close="closeLevelChip(index)"
                  v-for="(skill, index) in criteria.selectedLevel"
                  :key="index"
                  close
                  class="mr-2"
                  :color="backgrounds.level"
                >
                  Level: {{ skill }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
      <v-row v-if="showFilter" class="filter">
        <v-form class="w-100">
          <v-row>
            <v-col cols="12">
              <label> Select your skill</label>
              <v-autocomplete
                dense
                outlined
                :items="mappedSkills"
                item-title="text"
                item-value="id"
                v-model="criteria.selectedSkill"
                chips
                multiple
                hide-details
                closable-chips
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label> Select the time</label>
              <v-select
                item-title="text"
                item-value="value"
                dense
                outlined
                :items="filterData.duration"
                v-model="criteria.selectedTime"
                hide-details
                :return-object="false"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label> Select your Level </label>
              <v-select
                item-title="text"
                item-value="value"
                dense
                outlined
                :items="filterData.level"
                v-model="criteria.selectedLevel"
                chips
                multiple
                hide-details
                closable-chips
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import CoreSkill from "shared-components/src/models/CoreSkill";

export default defineComponent({
  props: {
    ShowBackBtn: {
      type: Boolean,
      default: true,
    },
    top: {
      type: String,
      default: "1px",
    },
    height: {
      type: String,
      default: "610px",
    },
    padding: {
      type: String,
      default: "40px",
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    skills: {
      type: Array as () => CoreSkill[],
      default: [],
    },
    clickOnOverlay: Function as PropType<(() => void) | any>,
  },
  data() {
    return {
      searchCategory: "",
      criteria: {
        selectedSkill: [] as string[],
        selectedLevel: [] as string[],
        selectedTime: null,
      },
      filterVisible: false,
      filterData: {
        duration: [
          { text: "Less than 5h", value: "0,5" },
          { text: "Between 5h and 10h", value: "5,10" },
          { text: "more than 10h", value: "10,9999" },
        ],
        level: [
          { text: "Easy", value: "Easy" },
          { text: "Medium", value: "Medium" },
          { text: "Hard", value: "Hard" },
        ],
      },
      backgrounds: {
        skill: "#0bffeb",
        duration: "#ccdb04",
        level: "#7fe300",
      },
    };
  },
  mounted() {},
  computed: {
    mappedSkills() {
      return this.skills.map((skill) => ({
        text: skill.Text,
        id: skill.id,
      }));
    },
    getSelectedTimeChip(): string {
      return `${(this.criteria.selectedTime as any).split(",")[0]}h - ${(this.criteria.selectedTime as any).split(",")[1]}h`;
    },
  },
  methods: {
    getSkillName(id: string) {
      return this.skills.filter((item) => item.id == id)[0].Text;
    },
    toggleFilter() {
      this.$emit("showOverlay", !this.showFilter);
    },
    searchHandler() {
      this.$emit("doSearch", {
        duration: this.criteria.selectedTime ? (this.criteria.selectedTime as any).split(",").map((item: any) => Number(item)) : null,
        level: this.criteria.selectedLevel,
        skill: this.criteria.selectedSkill,
        text: this.searchCategory,
      });
    },
    closeSkillChip(index: number) {
      const levels = [...this.criteria.selectedSkill];
      levels.splice(index, 1);
      this.criteria.selectedSkill = [];
      setTimeout(() => {
        this.criteria.selectedSkill = levels;
      }, 5);
    },
    closeLevelChip(index: number) {
      const levels = [...this.criteria.selectedLevel];
      levels.splice(index, 1);
      this.criteria.selectedLevel = [];
      setTimeout(() => {
        this.criteria.selectedLevel = levels;
      }, 5);
    },
    closeTimeChip() {
      this.criteria.selectedTime = null;
    },
    toggleNewCourse() {
      this.$router.push("/client/training/courses/new");
    },
  },
  watch: {
    criteria: {
      handler(val) {
        this.searchHandler();
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";

.filter-container {
  position: absolute;
  height: 100%;
  width: 100%;

  .default-section {
    background-color: $tarmac;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    margin-right: -30px;
    margin-left: -30px;
    margin-top: -15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .filter-button {
    background-color: $racecar;
    height: 55px !important;
  }

  .course-button {
    background-color: $racecar;
    left: 10px;
    height: 55px !important;
    padding: 0px 10px !important;
  }

  .sticky-navbar {
    position: sticky;
    z-index: 2001;
  }

  .search {
    margin-top: 3px;

    // .v-input .v-input__slot .v-label {
    //   font-size: medium;
    // }
  }

  .filter {
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-left: -30px;
    width: 20%;
    min-width: 210px;
    background-color: $tarmac;
    color: $track_lane;
    overflow-y: auto;
    z-index: 2100;
  }
  .submit {
    display: flex;
    justify-content: center;
    padding-left: 33px;
    padding-top: 0px;
  }
  .filter-items {
    margin-right: 4px;
  }
  .part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3px;
  }
}

@media only screen and (max-width: 600px) {
  .filter {
    padding-bottom: 20px;
    height: 440px !important;
  }
}
</style>
