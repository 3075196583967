import { messaging, firebaseVapidKey } from "shared-components/src/plugins/firebase";
import UserService from "./UserService";
import store from "@/store/index";
/**
 * Requests notification permission and retrieves the Firebase Cloud Messaging (FCM) token.
 * @returns null.
 */
export async function requestNotificationPermission(): Promise<void | null> {
  if (messaging && localStorage.idToken) {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const registration = await navigator.serviceWorker.ready;
        const currentToken = await messaging?.getToken( {
          vapidKey: firebaseVapidKey,
          serviceWorkerRegistration: registration,
        });

        if (currentToken) {
          if (localStorage.firebaseToken !== currentToken) {
            await UserService.sendPushToken(currentToken);
            localStorage.firebaseToken = currentToken;
          }
          messaging?.onMessage((payload) => {
            store.commit("UPDATE_NOTIFICATION_LIST");
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: payload.notification.body,
              icon: payload.notification.icon,
            };
            showNotification(notificationTitle, notificationOptions);
          });
        } else {
          console.error("No registration token available. Request permission to generate one.");
        }
      } else if (permission === "default") {
        Notification.requestPermission(function (permission) {
          if (permission === "granted") {
            messaging?.onMessage((payload) => {
              store.commit("UPDATE_NOTIFICATION_LIST");
              const notificationTitle = payload.notification.title;
              const notificationOptions = {
                body: payload.notification.body,
                icon: payload.notification.icon,
              };
              showNotification(notificationTitle, notificationOptions);
            });
          }
        });
      }
      else {
        console.error("Unable to get permission to notify.");
      }
    } catch (error) {
      console.error("An error occurred while retrieving token. ", error);
    }
  }
}

/**
 * Checks if the notification permission has been granted.
 * @returns True if permission is granted, else false.
 */
export function isNotificationPermissionGranted(): boolean {
  return Notification.permission === 'granted';
}

export function showNotification(notificationTitle: string, notificationOptions: any): void {
  var notification = new Notification(notificationTitle, notificationOptions);
  notification.onclick = function () {
    window.focus();
  };
}