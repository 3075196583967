import { AppState } from ".";
import SnakbarModel from "shared-components/src/models/SnakbarModel";
import UserInfo from "shared-components/src/models/UserInfo";
import noProfileImage from "@/assets/images/no-profile.png";
import { Profile } from "shared-components/src/definitions/config";

export const snakbarState: SnakbarModel = {
  id: "",
  body: "",
  timeout: Profile.ErrorSetting.SnackbarTimeout,
  status: "",
  button: "",
  show: false,
  handler: () => null,
};

export const defaultUser: UserInfo = {
  id: undefined,
  isAuthenticated: false,
  userName: null,
  fullName: "guest user",
  avatar: noProfileImage,
  title: null,
  name: "guest",
  surname: "user",
  lastSignInTime: null,
  roles: "",
  email: null,
  emailVerified: false,
  phoneNumber: "",
  creationDate: "",
  ipDetail: null
};

export const state: AppState = {
  quickLoginDialog: false,
  isProfileDropdownOpen: false,
  isNotificationOpen: false,
  drawerIsOpen: false,
  userInfo: { ...defaultUser },
  teamMemberInfo: null,
  showLoading: false,
  idToken: "",
  hasFirebaseAuth: false,
  snakbars: [],
  updateNotificationListFlag: false,
  careerObjectives: [],
  countries:[],
  cities:[]
};
