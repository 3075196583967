<template>
  <div>
    <v-row>
      <v-col></v-col>
      <v-col class="mt-4 mb-6 flex text-center">
        <v-btn class="" disabled color="accent" @click="googleAuth">
          <v-icon class="mr-2" color="white">mdi mdi-google</v-icon>
          Login With Google
        </v-btn>
      </v-col>
      <v-col></v-col>
    </v-row>
    <LoginForm form-id="login-form" @submitting="onSubmitting($event)" @success="onLoginSuccess" />
    <v-row>
      <v-col md="12" sm="12" cols="12">
        <div class="actions">
          <v-btn id="tm_login_button" :disabled="submitting" :loading="submitting" color="accent" type="submit" form="login-form">Login</v-btn>
          <v-btn id="tm_login_signup_button" class="secondary" to="/register/signup"> Signup </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoginForm from "@/components/User/LoginForm.vue";
import TeamMemberService from "@/services/TeamMemberService";
import UserInfo from "shared-components/src/models/UserInfo";
import UserService from "@/services/UserService";
import { Teammember } from "shared-components/src/models/Teammember";
import { firebaseApp } from "shared-components/src/plugins/firebase";
import store from "@/store";

export default defineComponent({
  components: {
    LoginForm,
  },
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    onSubmitting(value: any) {
      this.submitting = value;
    },
    showError(error: string) {
      store.dispatch("showErrorMessage", error);
    },
    onLoginSuccess(userInfo: UserInfo) {
      const returnUrl = this.$route.query.returnUrl as string;
      this.$router
        .push(returnUrl || "/team-member/dashboard")
        .then((res) => {
          return res;
        })
        .catch(() => {
          /**/
        });
    },
    setUserInfo(userInfo: UserInfo) {
      return store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    async logginIn(userInfo: any) {
      await this.setUserInfo(userInfo);
      try {
        const teamMemberInfo = await TeamMemberService.getTeammemberByEmail(userInfo.email || "");
        if (teamMemberInfo) {
          this.setTeamMemberInfo(teamMemberInfo);
        }
      } catch (err: any) {
        this.showError(err);
      }
      this.onLoginSuccess(userInfo);
    },
    googleAuth() {
      try {
        this.submitting = true;
        const provider = new firebaseApp.auth.GoogleAuthProvider();
        firebaseApp
          .auth()
          .signInWithPopup(provider)
          .then(async (res: any) => {
            try {
              const userInfo = await UserService.login(res);
              await this.logginIn(userInfo);
            } catch (error) {
              try {
                const userInfo = await UserService.signupAndLogin(res);
                await this.logginIn(userInfo);
              } catch (error: any) {
                this.showError(error);
                this.submitting = true;
              }
            }
          })
          .catch((err: any) => {
            this.submitting = false;
          });
      } catch (ex: any) {
        this.showError(ex);
        this.submitting = false;
      }
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },
});
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;
  .v-btn {
    flex-grow: 1;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
.v-btn {
  height: 45px !important;
}
</style>
