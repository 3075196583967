import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/code-clan-logo.svg'


const _hoisted_1 = {
  key: 0,
  class: "logo",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_HeaderTitle = _resolveComponent("HeaderTitle")!
  const _component_ProdileDropdown = _resolveComponent("ProdileDropdown")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_navigation_drawer, {
      dark: "",
      fixed: "",
      temporary: !_ctx.drawerPermanent,
      permanent: _ctx.drawerPermanent,
      rail: _ctx.drawerMini,
      modelValue: _ctx.drawerIsOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawerIsOpen) = $event)),
      "clipped-top": "",
      class: "app-drawer",
      width: "220"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["logo-container", { 'logo-container--mini': !_ctx.drawerIsOpen }])
        }, [
          _createVNode(_component_v_app_bar_nav_icon, {
            class: "toggler-icon",
            onClick: _ctx.toggleDrawerOpen
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass({
              'v-icon': true,
              notranslate: true,
              mdi: true,
              'mdi-chevron-right': !_ctx.drawerIsOpen,
              'mdi-chevron-left': _ctx.drawerIsOpen,
            })
              }, null, 2)
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.drawerIsOpen)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_NavigationMenu, {
          isAuthenticated: _ctx.isAuthenticated,
          profileCompleted: _ctx.profileCompleted,
          isLinemanager: _ctx.isLinemanager,
          userInfo: _ctx.userInfo,
          commitments: _ctx.commitments,
          hasActiveCommitment: _ctx.hasActiveCommitment
        }, null, 8, ["isAuthenticated", "profileCompleted", "isLinemanager", "userInfo", "commitments", "hasActiveCommitment"])
      ]),
      _: 1
    }, 8, ["temporary", "permanent", "rail", "modelValue"]),
    _createVNode(_component_v_app_bar, {
      app: "",
      color: "primary",
      height: "68"
    }, {
      default: _withCtx(() => [
        (!_ctx.drawerIsOpen && _ctx.drawerMini)
          ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
              key: 0,
              class: "toggler-icon",
              onClick: _ctx.toggleDrawerOpen
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("i", {
                  id: "navigation-menu-mdi-chevron-right",
                  class: _normalizeClass({
            'v-icon': true,
            notranslate: true,
            mdi: true,
            'mdi-chevron-right': true,
          })
                }, null, -1)
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.drawerIsOpen && !_ctx.drawerMini)
          ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
              key: 1,
              class: "hamburgur-toggler",
              onClick: _ctx.toggleDrawerOpen
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_HeaderTitle),
        _createVNode(_component_ProdileDropdown, { commitments: _ctx.commitments }, null, 8, ["commitments"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_main, {
      ref: "mainRef",
      id: "main-body"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, {
          fluid: "",
          class: "pages-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_Transition, {
              name: "slide-y-transition",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.quickLoginDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.quickLoginDialog) = $event)),
      "max-width": "500",
      persistent: "",
      scrollable: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "headline" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Login")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("p", null, "You must be logged in again because you are logged out", -1)),
                _createVNode(_component_LoginForm, {
                  "form-id": "main-login-form",
                  onSuccess: _ctx.onLoginSuccess,
                  onSubmitting: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmitting($event)))
                }, null, 8, ["onSuccess"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  disabled: _ctx.submitting,
                  loading: _ctx.submitting,
                  color: "green darken-1",
                  text: "",
                  form: "main-login-form",
                  type: "submit"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("OK")
                  ])),
                  _: 1
                }, 8, ["disabled", "loading"]),
                _createVNode(_component_v_btn, {
                  disabled: _ctx.submitting,
                  color: "green darken-1",
                  text: "",
                  onClick: _ctx.closeQuickLoginDialog
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}