import store from "@/store";
import UserInfo from "shared-components/src/models/UserInfo";
import { auth } from "shared-components/src/plugins/firebase";
import firebase from "firebase/compat/app";
import { defaultUser } from "@/store/state";
import Utils from "shared-components/src/utils/Utils";
import Infrastructure from "shared-components/src/utils/Infrastructure";
import ApiService from "./ApiService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import { Teammember } from "shared-components/src/models/Teammember";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";
import UserServiceV2 from "shared-components/src/services/UserService";

export default class UserService {
  public static async loginWithToken(): Promise<UserInfo> {
    const result = await ApiService.post(`/authentication/LoginWithCustomToken`, { Token: localStorage.idToken });
    if (!result.data) {
      return defaultUser;
    }
    let user: UserInfo;
    if (result.data) {
      try {
        user = await UserService.getUserData(result.data);
      } catch (error) {
        user = {
          isAuthenticated: result.data.user.isAnonymous === false,
          id: result.data.user.uid || "-1",
          email: result.data.user.email || null,
          fullName: result.data.user.displayName || "",
          emailVerified: Infrastructure.isTestingUser(result.data.user.email || "") ? true : result.data.user.emailVerified || false,
          phoneNumber: result.data.user.phoneNumber || null,
          avatar: result.data.user.photoURL,
          title: "",
          lastSignInTime: null,
          name: "",
          surname: "",
          userName: result.data.user.email || null,
          roles: "",
          creationDate: result.data.user.creationDate,
        } as UserInfo;
      }
    } else {
      user = defaultUser;
    }
    store.dispatch("setUserInfo", { userInfo: user, vm: null });
    return user;
  }

  public static async loginToken(data: any): Promise<UserInfo> {
    let result;
    if (data.idToken && data.refreshToken) {
      const token = data.idToken;
      localStorage.idToken = token;
      localStorage.refreshToken = data.refreshToken;
    }

    var user = await UserService.loginWithToken();
    return user;
  }

  public static async login(data: any): Promise<UserInfo> {
    let result;
    if (data.userName) {
      result = await UserServiceV2.login(data);
      if (!result.data) {
        return defaultUser;
      }
    } else {
      if (data.credential && data.credential.accessToken) {
        result = await ApiService.post(`/authentication/LoginWithGoogle`, { OAuthAccessToken: data.credential.accessToken });
        if (!result.data) {
          return defaultUser;
        }
      } else {
        result = data;
      }
    }
    if (result && result.data.user) {
      const token = result.data.jwtToken;
      localStorage.idToken = token;
      localStorage.refreshToken = result.data.refreshToken;
      //result = await ApiService.post(`/authentication/LoginWithCustomToken/false`, { Token: token });
    }
    let user: UserInfo;
    if (result.data) {
      try {
        user = await UserService.getUserData(result.data);
      } catch (error) {
        user = {
          isAuthenticated: result.data.isAnonymous === false,
          id: result.data.uid || "-1",
          email: result.data.email || null,
          fullName: result.data.displayName || "",
          emailVerified: Infrastructure.isTestingUser(result.data.email || "") ? true : result.data.isVerified || false,
          phoneNumber: result.data.phoneNumber || null,
          avatar: result.data.photoUrl,
          title: "",
          lastSignInTime: null,
          name: "",
          surname: "",
          userName: result.email || null,
          roles: "",
          creationDate: result.data.userMetaData.creationTimestamp,
          ipDetail: result.data.ipDetail
        } as UserInfo;
      }
    } else {
      user = defaultUser;
    }
    return user;
  }

  public static async reload(): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      if (auth.currentUser) {
        auth.currentUser
          .reload()
          .then((reloadResult: any) => {
            resolve(UserService.getUserInfo(auth.currentUser));
          })
          .catch((reloadError: any) => {
            reject(reloadError);
          });
      } else {
        reject("user not loggedin!");
      }
    });
  }

  public static resetPassword(email: string, callbackUrl: string): Promise<void> {
    return UserServiceV2.resetPassword(email, callbackUrl);
  }

  public static validateResetToken(token: string): Promise<string> {
    return UserServiceV2.validateResetToken(token);
  }

  public static saveNewPassword(password: string, token: string): Promise<string> {
    return UserServiceV2.saveNewPassword(password, token);
  }

  public static async logout(token: any) {
    if (localStorage.idToken) {
      UserService.removePushToken(token);
      localStorage.idToken = "";
      localStorage.refreshToken = "";
      if ((window as any)["webkit"] && (window as any)["webkit"].messageHandlers && (window as any)["webkit"].messageHandlers.nativeApp) {
        (window as any)["webkit"].messageHandlers.nativeApp.postMessage("logout");
      }
    }
    store.dispatch("setTeamMemberInfo", { teamMemberInfo: null, vm: this });
    store.dispatch("setUserInfo", { userInfo: defaultUser, vm: null });
  }

  public static async getUserInfo(user: any): Promise<UserInfo> {
    let userRecord;
    if (!user) {
      if (store.state.userInfo && store.state.userInfo.email) {
        userRecord = await ApiService.get(`/authentication/GetUserByEmail/${store.state.userInfo.email}`, "");
        if (!userRecord.data || userRecord.data.statusCode != SystemErrors.Authentication.Success || !userRecord.data.value) {
          return defaultUser;
        } else {
          return await UserService.getUserData(userRecord.data.value);
        }
      } else {
        return defaultUser;
      }
    } else {
      return await UserService.getFirebaseUserData(user);
    }
  }

  public static signupAndLogin(data: any): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      const teamMember = {
        UserAccountsId: data.user.uid,
        FullName: data.user.displayName,
        Email: data.user.email,
        Mobile: data.user.phoneNumber,
        PhotoUrl: data.user.photoUrl,
      } as Teammember;
      TeamMemberService.getTeammemberByEmail(teamMember.Email)
        .then((result) => {
          if (!result || result.Id == null) {
            TeamMemberService.create(teamMember)
              .then((teammemberResult) => {
                UserService.login(data)
                  .then((userInfo) => {
                    resolve(userInfo);
                  })
                  .catch((err2) => {
                    reject(err2);
                  });
              })
              .catch((teammemberError) => {
                reject(teammemberError);
              });
          } else {
            UserService.login(data)
              .then((userInfo) => {
                resolve(userInfo);
              })
              .catch((err2) => {
                reject(err2);
              });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static signup(email: any, password: any, firstName: any, LastName: any, phone: any, wayToKnow: any): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      UserServiceV2.register(email, password, firstName, LastName, phone, wayToKnow)
        .then((res) => {
          UserService.login({ userName: email, password })
            .then((userInfo) => {
              resolve(userInfo);
            })
            .catch((err2) => {
              reject(err2);
            });
        })
        .catch((error) => {
          reject("User with this email address/phone number already exists, please login!");
        });
    });
  }

  public static async sendEmailConfirmation(callbackUrl: string) {
    return new Promise((resolve, reject) => {
      ApiService.post("/authentication/SendVerificationEmail", { CallbackUrl: callbackUrl }).then((res) => {
        resolve(res.data);
      });
    });
  }

  public static async validateEmailConfirmation(token: string) {
    return new Promise((resolve, reject) => {
      ApiService.post("/authentication/ValidateVerificationEmail", { Token: token }).then((res) => {
        resolve(res.data);
      });
    });
  }

  public static async sendPushToken(token: any) {
    return new Promise((resolve, reject) => {
      ApiService.post("/authentication/SendUserDevice", { FirebaseToken: token, DeviceType: "Browser", Model: "" }).then((res) => {
        resolve(res.data);
      });
    });
  }

  public static async removePushToken(token: any) {
    return new Promise((resolve, reject) => {
      ApiService.post("/authentication/RemoveUserDevice", { FirebaseToken: token }).then((res) => {
        resolve(res.data);
      });
    });
  }

  private static getCustomToken(token: string): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/user/getTeammemberToken/${token}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private static getFirebaseUserData(user: firebase.User): Promise<UserInfo> {
    let roles = "";
    return new Promise((resolve, reject) => {
      user.getIdTokenResult().then((tknRsult: any) => {
        roles = tknRsult.claims.roles;
        resolve({
          isAuthenticated: user.isAnonymous === false,
          id: user.uid || "-1",
          email: user.email || null,
          fullName: user.displayName || "",
          emailVerified: Infrastructure.isTestingUser(user.email || "") ? true : user.emailVerified || false,
          phoneNumber: user.phoneNumber || null,
          avatar: user.photoURL,
          title: "",
          lastSignInTime: null,
          name: "",
          surname: "",
          userName: user.email || null,
          roles,
          creationDate: user.metadata.creationTime,
        } as UserInfo);
      });
    });
  }

  private static getUserData(data: any): Promise<UserInfo> {
    let role = "";
    return new Promise((resolve, reject) => {
      if (data && data.user && data.user.role) {
        role = data.user.role;
      }
      resolve({
        isAuthenticated: !data.isAnonymous || data.isAnonymous === false,
        id: data.user.id || "-1",
        email: data.user.email || null,
        fullName: data.user.displayName || "",
        emailVerified: Infrastructure.isTestingUser(data.user.email || "") ? true : data.user.isVerified || false,
        phoneNumber: data.user.phoneNumber || null,
        avatar: data.user.photoUrl,
        title: "",
        lastSignInTime: null,
        name: "",
        surname: "",
        userName: data.user.email || null,
        roles: role,
        creationDate: data ? data.user.creationDate : Utils.toVsDateFormat(new Date()),
        ipDetail: data.ipDetail
      } as UserInfo);
    });
  }
}
